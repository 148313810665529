import {
  getRelativeFontSize,
  inputLabelRequiredColor,
  mediumFont,
  regularFont,
  theme,
} from "../../../utils/styles";

const customInputStyles = {
  textField: {
    width: "100%",
    color: "#ffffff",
    background: "#2f2f2f",
    border: "none",
    borderRadius: "10px",

    "& .MuiInputBase-input": {
      position: "relative",
      padding: "12px 12px",
      border: "none",
      color: "#ffffff",
      "&::placeholder": {
        color: "#f2f2f2",
        ...regularFont,
      },
    },
    "& .MuiOutlinedInput-root": {
      borderRadius: "10px",
      border: "none",
      padding: "5px 10px",
      "&.Mui-focused fieldset": {
        border: "none",
      },
    },
  },
  nameField: {
    color: "#ffffff",
    marginLeft: "6px",
    ...mediumFont,
    fontSize: getRelativeFontSize(3),
    "& .MuiFormLabel-asterisk": {
      color: inputLabelRequiredColor,
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: getRelativeFontSize(2),
    },
  },
  errorStyle: {
    ...mediumFont,
    fontSize: getRelativeFontSize(1),
    marginLeft: "6px",
    color: "#d32f2f",
  },
} as const;

export default customInputStyles;
