import {
  boldFont,
  mediumFont,
  primaryColorPurple,
  theme,
  primaryActiveTabBgColor,
} from "../../../utils/styles";

const customTabsStyles = {
  headerBox: {
    background: "#FFFFFF",
    [theme.breakpoints.down("sm")]: {},
  },
  mainBox: {
    padding: "15px 30px",
  },
  customTabsText: {
    ...boldFont,
  },
  outerTabBox: {
    marginTop: "10px",
    borderBottom: "none",
  },
  tab: {
    "& .MuiButtonBase-root-MuiTab-root": {
      padding: "12px 2px",
    },
  },
  tabBox: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "2px",
    borderRadius: "10px",
    height: "40px",
    width: "100%",
  },
  active: {
    backgroundColor: primaryActiveTabBgColor,
    color: primaryColorPurple,
    textDecoration: "none",
    ...mediumFont,
  },
  inActive: {
    backgroundColor: "#F9F9F9",
    color: "#828282",
  },
  tabText: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    ...mediumFont,
    margin: theme.spacing(1),
    textTransform: "none",
  },
  counts: {
    marginLeft: theme.spacing(2),
    opacity: "0.5",
    // marginRight: theme.spacing(1),
  },

  searchIcon: {
    marginLeft: theme.spacing(1),
  },
  input: {
    border: "1px solid #E7E7E7",
  },
  outerBox: {
    background: primaryColorPurple,
    borderRadius: "10px",
    // p: "1px",
    width: "95px",
  },
  btnBox: {
    color: "white",
    textTransform: "none",
    justifyContent: "space-between",
  },
  addBox: {
    background: "#4F31BC",
    width: "32px",
    height: "32px",
    borderRadius: "10px",
    color: "#FFFFF",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
};

export default customTabsStyles;
