import {
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
} from "@mui/material";
import { useState } from "react";
import Visibility from "../../../assets/icons/Visibility.svg";
import VisibilityOff from "../../../assets/icons/VisibilityOff.svg";
import { CustomInput } from "../../../global/components";
import strings from "../../../global/constants/StringConstants";
import { isTruthy } from "../../../helpers/methods";
import { LoginFields } from "../../../models/interfaces";

const LoginInputs = ({
  classes,
  formFields,
  setFormFields,
  handleSubmit,
  passwordField,
}: {
  classes: any;
  formFields: LoginFields;
  setFormFields: Function;
  handleSubmit: Function;
  passwordField: boolean;
}) => {
  const emailRegex = strings.regex;
  const [showPassword, setShowPassword] = useState(false);

  const handleOnChangeInputField = (event: React.ChangeEvent<any>) => {
    setFormFields({
      ...formFields,
      [event.target.name]: {
        ...formFields[event.target.name],
        value: event.target.value,
      },
    });
  };

  const handleClickShowPassword = () => {
    setShowPassword(showPassword);
  };

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    setShowPassword(!showPassword);
    event.preventDefault();
  };

  const handleKeypress = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === "Enter") {
      e.preventDefault();
      handleSubmit();
    }
  };

  return (
    <>
      <Grid container sx={classes.center} spacing={2}>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <CustomInput
            required
            placeHolder="username@email.com"
            type="email"
            name="email"
            label="Email"
            onChange={handleOnChangeInputField}
            value={formFields.email.value}
            onKeyPress={handleKeypress}
            sx={{
              "& .MuiOutlinedInput-root": {
                padding: "0px",
                "&.Mui-focused fieldset": {
                  border: "none",
                },
              },
            }}
            propsToInputElement={{ maxLength: 50 }}
            error={!isTruthy(formFields.email.value) && formFields.email.error}
          />
          {!emailRegex.test(formFields.email.value) &&
            formFields.email.value.length > 0 && (
              <FormHelperText error sx={classes.errorStyling}>
                Please enter valid email id
              </FormHelperText>
            )}
        </Grid>
      </Grid>
      {passwordField && (
        <Grid container sx={classes.center} spacing={2}>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12} mt={3}>
            <CustomInput
              required
              placeHolder="Enter Password"
              type={showPassword ? "text" : "password"}
              name="password"
              label="Password"
              onChange={handleOnChangeInputField}
              value={formFields.password.value}
              onKeyPress={handleKeypress}
              sx={{
                "& .MuiOutlinedInput-root": {
                  padding: "0px",
                  "&.Mui-focused fieldset": {
                    border: "none",
                  },
                },
              }}
              propsToInputElement={{ maxLength: 30 }}
              error={
                formFields.password.value.length < 8
                  ? formFields.password.error
                  : null
              }
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                      sx={{ pr: 2.5 }}
                    >
                      {showPassword ? (
                        <img
                          src={Visibility}
                          alt=""
                          style={{ margin: "8px" }}
                        />
                      ) : (
                        <img
                          src={VisibilityOff}
                          alt=""
                          style={{ margin: "8px" }}
                        />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default LoginInputs;
