import ActiveCandidates from "../assets/icons/ActiveCandidates.svg";
import ActiveTickets from "../assets/icons/ActiveTickets.svg";
import Candidates from "../assets/icons/Candidates.svg";
import Tickets from "../assets/icons/Tickets.svg";
import strings from "../global/constants/StringConstants";
import urls from "../global/constants/UrlConstants";
import { isTruthy } from "../helpers/methods";
import { FormattedResources } from "../models/interfaces";
import { store } from "./store";

export interface ListOfMenu {
  icon: string;
  activeIcon: string;
  text: string;
  key: string;
  link: string;
  pageName: string;
  accessToResource: string[];
  permissions: string[];
  sidebarMapping: SideBarMapping[];
}

export type SideBarMapping = {
  resource: string;
  permissions: string[];
  allPermissionRequired: boolean;
};

export const MainMenus = [
  {
    icon: Candidates,
    activeIcon: ActiveCandidates,
    text: "Chat",
    key: "/chat",
    link: urls.chatViewPath,
    pageName: strings.HOME,
    sidebarMapping: [
      {
        resource: strings.APP,
        permissions: [strings.fetchPermission],
        allPermissionRequired: true,
      },
    ],
  },
  // {
  //   icon: Candidates,
  //   activeIcon: ActiveCandidates,
  //   text: "example",
  //   key: "/example",
  //   link: urls.dashboardViewPath,
  //   pageName: strings.DASHBOARD,
  //   subMenu: [
  //     {
  //       icon: Candidates,
  //       activeIcon: ActiveCandidates,
  //       text: "subMenu",
  //       key: "/dashboard",
  //       link: urls.dashboardViewPath,
  //       pageName: strings.DASHBOARD,
  //       sidebarMapping: [
  //         {
  //           resource: strings.APP,
  //           permissions: [strings.fetchPermission],
  //           allPermissionRequired: true,
  //         },
  //       ],
  //     },
  //   ],
  //   sidebarMapping: [
  //     {
  //       resource: strings.APP,
  //       permissions: [strings.fetchPermission],
  //       allPermissionRequired: true,
  //     },
  //   ],
  // },
  {
    icon: Candidates,
    activeIcon: ActiveCandidates,
    text: "Employees",
    key: "/employees",
    link: urls.employeesViewPath,
    pageName: strings.EMPLOYEES,
    sidebarMapping: [
      {
        resource: strings.APP,
        permissions: [strings.fetchPermission],
        allPermissionRequired: true,
      },
    ],
  },
  {
    icon: Tickets,
    activeIcon: ActiveTickets,
    text: "Tickets",
    key: "/tickets",
    link: urls.ticketsViewPath,
    pageName: strings.TICKETS,
    sidebarMapping: [
      {
        resource: strings.APP,
        permissions: [strings.fetchPermission],
        allPermissionRequired: true,
      },
    ],
  },
  {
    icon: Tickets,
    activeIcon: ActiveTickets,
    text: "Settings",
    key: "/settings",
    link: urls.settingsViewPath,
    pageName: strings.SETTINGS,
    sidebarMapping: [
      {
        resource: strings.APP,
        permissions: [strings.fetchPermission],
        allPermissionRequired: true,
      },
    ],
  },
] as ListOfMenu[];

export const generateMenu = (mainMenus: ListOfMenu[] = MainMenus) => {
  const resources = store.getState().auth.resources;
  const generatedMenu: ListOfMenu[] = [];
  mainMenus.forEach((menu: ListOfMenu) => {
    const hasAccess = menu?.sidebarMapping?.some(
      (sideBarAccess: SideBarMapping) => {
        if (
          resources[strings.APP]?.includes(strings.adminPermission) ||
          resources[strings.ACCOUNT]?.includes(strings.adminPermission)
        ) {
          return true;
        }

        if (!resources.hasOwnProperty(sideBarAccess.resource)) {
          return false;
        } else {
          const permissions = resources[sideBarAccess.resource];
          const hasAllPermissions = sideBarAccess.permissions?.every(
            (indPermission: string) => permissions.includes(indPermission)
          );

          if (sideBarAccess.allPermissionRequired) {
            return hasAllPermissions ?? false;
          } else {
            return sideBarAccess.permissions?.some((indPermission: string) =>
              permissions.includes(indPermission)
            );
          }
        }
      }
    );

    if (hasAccess) {
      generatedMenu.push(menu);
    }
  });

  return generatedMenu;
};

export const doesUserHasAccessToMenuItem = (
  componentName: string,
  userMenu?: ListOfMenu[]
) => {
  let hasAccess = false;
  const menus = generateMenu()?.map(
    (menu: ListOfMenu) => menu.pageName === componentName
  );
  const resources = store.getState().auth.resources;
  hasAccess = isTruthy(menus);
  return hasAccess;
};

export const doesLoggedInUserHasAccess = (
  buttonName: string,
  resourceName: string
) => {
  const resources: FormattedResources = store.getState().auth.resources ?? [];
  if (resources.hasOwnProperty(resourceName)) {
    if (resources[resourceName].indexOf(buttonName) !== -1) {
      return true;
    }
  }
  return false;
};

export const appAdminUserSpecificPermission = () => {
  const resources: FormattedResources = store.getState().auth.resources ?? [];
  if (resources.hasOwnProperty(strings.APP.toLocaleLowerCase())) {
    if (
      resources[strings.APP.toLocaleLowerCase()].indexOf(
        strings.adminPermission.toLocaleLowerCase()
      ) !== -1
    ) {
      return true;
    }
  }
  return false;
};
export const accountAdminUserSpecificPermission = () => {
  const resources: FormattedResources = store.getState().auth.resources ?? [];
  if (resources.hasOwnProperty(strings.ACCOUNT.toLocaleLowerCase())) {
    if (
      resources[strings.ACCOUNT.toLocaleLowerCase()].indexOf(
        strings.adminPermission.toLocaleLowerCase()
      ) !== -1
    ) {
      return true;
    }
  }
  return false;
};
export const appAndAccountAdminCommonUserSpecificPermission = () => {
  const resources: FormattedResources = store.getState().auth.resources ?? [];
  if (
    resources.hasOwnProperty(strings.ACCOUNT.toLocaleLowerCase()) &&
    resources.hasOwnProperty(strings.APP.toLocaleLowerCase())
  ) {
    if (
      resources[strings.ACCOUNT.toLocaleLowerCase()].indexOf(
        strings.adminPermission.toLocaleLowerCase()
      ) !== -1 &&
      resources[strings.APP.toLocaleLowerCase()].indexOf(
        strings.adminPermission.toLocaleLowerCase()
      ) !== -1
    ) {
      return true;
    }
  }
  return false;
};
export const appOrAccountAdminCommonUserSpecificPermission = () => {
  const resources: FormattedResources = store.getState().auth.resources ?? [];
  if (
    resources.hasOwnProperty(strings.ACCOUNT.toLocaleLowerCase()) ||
    resources.hasOwnProperty(strings.APP.toLocaleLowerCase())
  ) {
    if (
      resources[strings.ACCOUNT.toLocaleLowerCase()].indexOf(
        strings.adminPermission.toLocaleLowerCase()
      ) !== -1 ||
      resources[strings.APP.toLocaleLowerCase()].indexOf(
        strings.adminPermission.toLocaleLowerCase()
      ) !== -1
    ) {
      return true;
    }
  }
  return false;
};
export const doesLoggedInUserHasAccessToResource = (
  buttonName: string,
  resourceName: string
) => {
  if (
    [
      doesLoggedInUserHasAccess(buttonName, resourceName),
      appAdminUserSpecificPermission(),
      accountAdminUserSpecificPermission(),
      appAndAccountAdminCommonUserSpecificPermission(),
    ].includes(true)
  ) {
    return true;
  }
  return false;
};
