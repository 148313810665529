import { Route, Redirect, RouteProps } from "react-router-dom";
import {
  doesUserHasAccessToMenuItem,
  generateMenu,
} from "../../../utils/AuthorizationManager";
import urls from "../../constants/UrlConstants";

interface CustomProps extends RouteProps {
  component?: any;
  isLoggedIn: boolean;
  componentName: string;
}

const PrivateRoute = (props: CustomProps) => {
  const { component: Component, isLoggedIn, componentName, ...rest } = props;

  return (
    <Route
      {...rest}
      render={(routeProps) =>
        isLoggedIn &&
        doesUserHasAccessToMenuItem(componentName, generateMenu()) ? (
          <Component {...routeProps} />
        ) : (
          <Redirect
            to={{
              pathname: urls.landingViewPath,
              state: {
                from: routeProps.location,
                search: routeProps.location.search,
              },
            }}
          />
        )
      }
    />
  );
};

export default PrivateRoute;
