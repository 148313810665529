import strings from "../../global/constants/StringConstants";
import { LoginFields, ResetPassword } from "../../models/interfaces";

export const loginForm = () => {
  return {
    email: {
      value: "",
      error: "",
    },
    password: {
      value: "",
      error: "",
    },
  } as LoginFields;
};

export const loginValidation = (loginUserData: any) => {
  let errors = loginUserData;
  let isValid = true;
  const emailRegex = strings.regex;
  const email = loginUserData.email.value;
  const password = loginUserData.password.value;

  if (!email && !password) {
    errors.email.error = "Email is required";
    errors.password.error = "Password is required";
    isValid = false;
  }
  if (!email) {
    errors.email.error = "Please enter email Id";
    isValid = false;
  }
  if (email) {
    if (!emailRegex.test(email)) {
      errors.email.error = "Please enter valid E-mail!";
      isValid = false;
    }
  }
  if (!password) {
    errors.password.error = "Please enter password";
    isValid = false;
  }
  if (password && password.length < 8) {
    errors.password.error = "Password must be 8 character long";
    isValid = false;
  }
  return { isValid, errors };
};

export const forgotPasswordValue = () => {
  return {
    email: {
      value: "",
      error: "",
    },
  } as LoginFields;
};

export const forgotPasswordValidation = (forgotUserData: any) => {
  let errors = forgotUserData;
  let isValid = true;
  const email = forgotUserData.email.value;
  if (!email) {
    errors.email.error = "Please enter email";
    isValid = false;
  }
  return { isValid, errors };
};

export const resetPasswordForm = () => {
  return {
    newPassword: {
      value: "",
      error: false,
    },
    confirmPassword: {
      value: "",
      error: false,
    },
  } as ResetPassword;
};

export const resetPasswordValidation = (formFields: any) => {
  let errors = formFields;
  let isValid = true;
  const newPassword = formFields.newPassword.value;
  const confirmPassword = formFields.confirmPassword.value;
  if (!newPassword && !confirmPassword) {
    errors.newPassword.error = "New Password is required!";
    errors.confirmPassword.error = "Confirm Password is required!";
  }
  if (!newPassword) {
    errors.newPassword.error = "New Password is required!";
    isValid = false;
  }
  if (!confirmPassword) {
    errors.confirmPassword.error = "Confirm Password is required!";
    isValid = false;
  }

  return { isValid, errors };
};
