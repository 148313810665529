import { Box, useMediaQuery } from "@mui/material";
import { useState } from "react";
import { Switch } from "react-router-dom";
import PrivateRoute from "../../../global/components/PrivateRoute/PrivateRoute";
import strings from "../../../global/constants/StringConstants";
import urls from "../../../global/constants/UrlConstants";
import { selectAuthenticated } from "../../../redux/authSlice";
import { useAppSelector } from "../../../utils/hooks";
import { theme } from "../../../utils/styles";
import Home from "../../Home/Home";
import PageNotFound from "../../PageNotFound/PageNotFound";
import layoutStyles from "./Layout.styles";

const Layout = () => {
  const classes = layoutStyles;
  const isAuthenticated = useAppSelector(selectAuthenticated);
  const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(true);
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));

  const getContent = () => {
    return (
      <Box>
        <Switch>
          <PrivateRoute
            exact
            isLoggedIn={isAuthenticated}
            componentName={strings.HOME}
            path={urls.chatViewPath}
            component={Home}
          />
          {/* <PrivateRoute
            exact
            isLoggedIn={isAuthenticated}
            componentName={strings.TICKETS}
            path={urls.ticketsViewPath}
            component={Tickets}
          />
          <PrivateRoute
            exact
            isLoggedIn={isAuthenticated}
            componentName={strings.EMPLOYEES}
            path={urls.employeesViewPath}
            component={Employees}
          />
          <PrivateRoute
            exact
            isLoggedIn={isAuthenticated}
            componentName={strings.EMPLOYEES}
            path={urls.employeesAddEditViewPath}
            component={AddEditEmployeeForm}
          />
          <PrivateRoute
            exact
            isLoggedIn={isAuthenticated}
            componentName={strings.SETTINGS}
            path={urls.settingsViewPath}
            component={Settings}
          /> */}
          <PrivateRoute
            exact
            isLoggedIn={isAuthenticated}
            path={""}
            component={PageNotFound}
            componentName={strings.PAGENOTFOUND}
          />
        </Switch>
      </Box>
    );
  };
  const toggleDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };

  const getLayout = () => {
    return <>{getContent()}</>;
  };

  return getLayout();
};

export default Layout;
