import {
  boldFont,
  getRelativeFontSize,
  regularFont,
  theme,
} from "../../../utils/styles";

const customTableStyles = {
  table: {
    borderCollapse: "separate",
    borderSpacing: "0 5px",
    borderTopRadius: "15px",
    borderBottomRadius: "15px",
    // overflow: "auto",
    width: "100%",
    [theme.breakpoints.down("xl")]: {
      overflow: "auto",
      // overflowY: "auto",
      // width: "100%",
    },
  },
  tableBody: {
    backgroundColor: "#FFFFFF",
    // backgroundColor: contrastBackgroundColor,
    maxHeight: "500px",
    overflow: "auto",
  },
  tableHeaderCell: {
    ...boldFont,
    color: "FFFFFF",
    backgroundColor: "#F2F2F2",
    borderTop: "1px solid #F0F0F0",
    borderBottom: "1px solid #F0F0F0",
    fontSize: getRelativeFontSize(3),
    padding: "12px 16px 12px 16px",
    whiteSpace: "noWrap",
  },
  tableCell: {
    ...regularFont,
    color: "#000000",
    height: "30px",
    borderTop: "1px solid #F0F0F0",
    borderBottom: "1px solid #F0F0F0",
    fontSize: getRelativeFontSize(2),
    cursor: "default",
  },
  tableRow: {
    margin: "10px 0",
    backgroundColor: "#FFFFFF",
    color: "#000000",
    "&:hover": {
      // backgroundColor: "rgba(237,237,237,0.7)",
      cursor: "pointer",
      // backgroundColor: contrastBackgroundColor,
    },
  },
  tableHeaderCellTypo: {
    ...boldFont,
    fontSize: getRelativeFontSize(2),
    whiteSpace: "noWrap",
  },
  pagination: {
    "&. MuiPaginationItem-root.Mui-selected": {
      backgroundColor: "#000000",
      color: "#000000",
    },
    "&.MuiTablePagination-displayedRows": {
      color: "red",
    },
    "&.MuiTablePagination-selectLabel": {
      color: "green",
    },
  },
} as const;

export default customTableStyles;
