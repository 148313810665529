import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import StopIcon from "@mui/icons-material/Stop";
import {
  Box,
  Container,
  Grid,
  IconButton,
  InputAdornment,
  Stack,
  Tooltip,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import ReactMarkdown from "react-markdown";
import { io, Socket } from "socket.io-client";
import { v4 as uuidv4 } from "uuid";
import loader from "../../assets/loader.gif";
import { CustomInput } from "../../global/components";
import CustomSelect from "../../global/components/CustomSelect/CustomSelect";
import { openSuccessNotification } from "../../helpers/methods";
import { primaryColorBlack, theme } from "../../utils/styles";
import homeStyles from "./Home.styles";

interface ChatMessage {
  id: string;
  question: string;
  answer: string;
  wordCount?: string;
}

const Home = () => {
  const classes = homeStyles;
  const isDownToXl = useMediaQuery(theme.breakpoints.down("xl"));
  const isUpToMd = useMediaQuery(theme.breakpoints.up("md"));
  const [socket, setSocket] = useState<Socket | null>(null);
  const [currentGeneratingId, setCurrentGeneratingId] = useState<string | null>(
    null
  );
  const [modelName, setModelName] = useState<string>("claude");
  const [content, setContent] = useState<string>("");
  const [displayContent, setDisplayContent] = useState<any>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [stopIds, setStopIds] = useState<string[]>([]);
  const bottomRef = useRef<null | HTMLDivElement>(null);
  const stopIdsRef = useRef<string[]>([]);

  useEffect(() => {
    connectSocket();
    return () => {
      socket?.disconnect();
    };
  }, []);

  useEffect(() => {
    stopIdsRef.current = stopIds;
  }, [stopIds]);

  useEffect(() => {
    const handleAutoScroll = () => {
      const container = bottomRef.current?.parentElement;
      if (!container) return;
      const isNearBottom =
        container.scrollHeight - container.scrollTop - container.clientHeight <
        50;
      if (isNearBottom) {
        bottomRef.current?.scrollIntoView({ behavior: "smooth" });
      }
    };

    handleAutoScroll();
  }, [displayContent]);

  const modelNameList = [
    {
      value: "claude",
      content: "Claude (Bedrock)",
    },
    {
      value: "o1-preview",
      content: "ChatGPT",
    },
  ];

  const connectSocket = () => {
    const newSocket = io("https://api.astroepigenetics.com");
    setSocket(newSocket);

    // Socket event listeners
    newSocket.on("connect", () => {
      console.log("Connected to server");
    });

    newSocket.on("response_start", () => {
      setIsLoading(true);
    });

    newSocket.on("response_chunk", (data) => {
      setDisplayContent((prevContent: any) => {
        const updatedContent = [...prevContent];
        const lastMessageIndex = updatedContent.length - 1;

        if (lastMessageIndex >= 0) {
          updatedContent[lastMessageIndex] = {
            ...updatedContent[lastMessageIndex],
            answer: (updatedContent[lastMessageIndex].answer || "") + data.text,
          };
        }

        return updatedContent;
      });
    });

    newSocket.on("response_complete", () => {
      setIsLoading(false);
      setCurrentGeneratingId(null);
    });

    newSocket.on("error", (errorData) => {
      console.error("Socket error:", errorData);
      setIsLoading(false);
      setCurrentGeneratingId(null);
    });
  };

  const handleModelNameChange = (event: any) => {
    setModelName(event.target.value);
  };

  const handleKeypress = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault();
      handleSubmit();
    }
  };

  const handleSubmit = () => {
    if (content.trim() && socket && !isLoading) {
      const newQuestionId = uuidv4();

      // Add question to display
      const newQuestion: ChatMessage = {
        id: newQuestionId,
        question: content,
        answer: "",
      };

      setDisplayContent((prev: any) => [...prev, newQuestion]);

      // Send prompt via socket
      socket.emit("prompt", {
        prompt: content,
        model: modelName,
      });

      // Set current generating ID and loading state
      setCurrentGeneratingId(newQuestionId);
      setContent("");
    }
  };

  const stopPrintingForQuestion = () => {
    if (!socket) return;
    socket.disconnect();
    setIsLoading(false);
    setTimeout(() => {
      connectSocket();
    }, 2000);
  };

  const cleanContent = (content: string) => {
    content = content.replace(/<\/?h[1-6]>/g, "\n");
    content = content.replace(/<\/?p>/g, "\n\n");
    content = content.replace(/<\/?div>/g, "\n");
    content = content.replace(/<\/?span>/g, "");
    content = content.replace(/<a [^>]*>(.*?)<\/a>/g, "$1");
    content = content.replace(/<\/?strong>/g, "**");
    content = content.replace(/<\/?b>/g, "**");
    content = content.replace(/<\/?em>/g, "*");
    content = content.replace(/<\/?i>/g, "*");
    content = content.replace(/<\/?ul>/g, "\n");
    content = content.replace(/<\/?ol>/g, "\n");
    content = content.replace(/<\/?li>/g, "\n- ");
    content = content.replace(/<\/?blockquote>/g, "\n> ");
    content = content.replace(/<\/?code>/g, "`");
    content = content.replace(/<\/?pre>/g, "\n```\n");
    content = content.replace(/<img [^>]*alt="(.*?)"[^>]*>/g, "$1");
    content = content.replace(/<\/?hr>/g, "\n---\n");
    content = content.replace(/<\/?[^>]+>/g, "");
    content = content.replace(/\n{3,}/g, "\n\n");
    content = content.replace(/#{1,6} (.*?)(\n|$)/g, "$1\n");
    content = content.replace(/\*\*(.*?)\*\*/g, "$1");
    content = content.replace(/\*(.*?)\*/g, "$1");
    content = content.replace(/^\d+\. /gm, "");
    content = content.replace(/^- /gm, "");
    content = content.replace(/\n{3,}/g, "\n\n");
    content = content.trim();

    return content;
  };

  const handleCopy = async (content: string) => {
    const cleanText = cleanContent(content);
    const isSecureContext = window.isSecureContext;
    if (!navigator.clipboard || !isSecureContext) {
      try {
        const textArea = document.createElement("textarea");
        textArea.value = cleanText;
        document.body.appendChild(textArea);
        textArea.select();
        document.execCommand("copy");
        document.body.removeChild(textArea);
        openSuccessNotification("Content copied to clipboard!");
      } catch (err: any) {
        console.log("Failed to copy using fallback method: ", err);
      }
      return;
    }
    try {
      await navigator.clipboard.writeText(cleanText);
      openSuccessNotification("Content copied to clipboard!");
    } catch (err: any) {
      console.log("Failed to copy: ", err);
    }
  };

  const getHeader = () => {
    return (
      <Box
        sx={{
          background: "#171717",
          width: "100%",
          py: 2,
          position: "fixed",
          zIndex: 10,
        }}
      >
        <Container maxWidth="xl" sx={{ height: "100%" }}>
          <Grid
            container
            spacing={2}
            justifyContent={"center"}
            alignContent={"center"}
            height={"100%"}
          >
            <Grid
              item
              xl={4}
              lg={4}
              md={4}
              sm={12}
              xs={12}
              alignContent={"end"}
            >
              <CustomSelect
                menuItems={modelNameList}
                onChange={handleModelNameChange}
                id={"modelName"}
                name={"modelName"}
                value={modelName}
              />
            </Grid>
          </Grid>
        </Container>
      </Box>
    );
  };

  const getOutputArea = () => {
    return (
      <Container maxWidth="md" sx={{ pt: isDownToXl && isUpToMd ? 18 : 12 }}>
        <Stack direction={"column"} spacing={3} pt={2} pb={12}>
          {displayContent.map((item: any) => {
            return (
              <React.Fragment key={item.id}>
                <Box
                  sx={{
                    background: "#2f2f2f",
                    p: 2,
                    borderRadius: "30px",
                    maxWidth: "600px",
                    alignSelf: "end",
                  }}
                >
                  <Typography
                    sx={{
                      color: "#ececec",
                    }}
                  >
                    {item.question}
                  </Typography>
                </Box>
                {item.answer && (
                  <React.Fragment>
                    <Stack
                      direction={"row"}
                      spacing={1}
                      sx={{
                        background: "#2f2f2f",
                        borderRadius: "10px 10px 0px 0px",
                      }}
                      justifyContent={"end"}
                    >
                      <Tooltip title="Copy formatted content">
                        <IconButton
                          onClick={() => handleCopy(item.answer)}
                          sx={{ color: "#e2e2e2" }}
                        >
                          <ContentCopyIcon />
                        </IconButton>
                      </Tooltip>
                    </Stack>
                    <Box
                      sx={{
                        position: "relative",
                        background: "#000000",
                        p: 2,
                        borderRadius: "0px 0px 10px 10px",
                        overflow: "hidden",
                        zIndex: 1,
                        mt: "0 !important",
                      }}
                    >
                      <ReactMarkdown className="answer">
                        {item.answer}
                      </ReactMarkdown>
                      {isLoading && item.id === currentGeneratingId && (
                        <Box ref={bottomRef}>
                          <img src={loader} alt="loader" height={"30px"} />
                        </Box>
                      )}
                    </Box>
                  </React.Fragment>
                )}
              </React.Fragment>
            );
          })}
        </Stack>
      </Container>
    );
  };

  const getInputBase = () => {
    return (
      <Box
        sx={{
          width: "100%",
          position: "fixed",
          bottom: 0,
          right: 0,
          color: primaryColorBlack,
          display: "flex",
          justifyContent: "center",
          background: "#212121",
          zIndex: 10,
        }}
      >
        <Container
          maxWidth="md"
          sx={{
            height: "100%",
            display: "flex",
            flexDirection: "column-reverse",
          }}
        >
          <Box mb={2}>
            <CustomInput
              multiline
              value={content}
              onChange={(event: any) => setContent(event.target.value)}
              onKeyPress={handleKeypress}
              placeHolder={"Write your message..."}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() => {
                        isLoading ? stopPrintingForQuestion() : handleSubmit();
                      }}
                      sx={{
                        background: "#ffffff",
                        "&:hover": {
                          background: "#c2c2c2",
                        },
                      }}
                    >
                      {isLoading ? (
                        <StopIcon htmlColor="#000000" />
                      ) : (
                        <ArrowUpwardIcon htmlColor="#000000" />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Box>
        </Container>
      </Box>
    );
  };

  const getHome = () => {
    return (
      <Box sx={{ background: "#212121", minHeight: "100vh" }}>
        {getHeader()}
        {getOutputArea()}
        {getInputBase()}
      </Box>
    );
  };

  return getHome();
};

export default Home;
