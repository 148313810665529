import { Box, Stack } from "@mui/material";
import { useRef, useState } from "react";
import {
  default as ReCAPTCHA,
  default as RECAPTCHA,
} from "react-google-recaptcha";
import { CustomButton } from "../../global/components";
import notifiers from "../../global/constants/NotificationConstants";
import strings from "../../global/constants/StringConstants";
import urls from "../../global/constants/UrlConstants";
import { isTruthy, openErrorNotification } from "../../helpers/methods";
import { loginAction } from "../../redux/authSlice";
import history from "../../utils/history";
import { useAppDispatch } from "../../utils/hooks";
import loginStyles from "./authStyles.styles";
import LoginInputs from "./sharedComponents/LoginInputs";
import { loginForm, loginValidation } from "./TypesAndValidations";

const Login = () => {
  const classes = loginStyles;
  const dispatch = useAppDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [formFields, setFormFields] = useState(loginForm);
  const reRef = useRef<RECAPTCHA>(null);

  const RECAPTCHA_SITE_KEY = strings.RECAPTCHA_SITE_KEY;

  const credentials = [
    {
      email: "gregory@patal.com",
      password: "Astroepigenetics#2024",
    },
    {
      email: "shivaay01102024@gmail.com",
      password: "Shivaay#2024",
    },
  ];

  const handleLogin = async () => {
    try {
      if (handleValidation()) {
        if (
          credentials.find(
            (cred) =>
              cred.email === formFields.email.value &&
              cred.password === formFields.password.value
          )
        ) {
          dispatch(
            loginAction({
              authenticated: true,
              accessToken: "",
              userName: "Astroepigenetics GPT",
              userEmail: formFields.email.value,
              userRole: "Admin",
              resources: {
                app: ["admin"],
              },
            })
          );
          history.push(urls.chatViewPath);
        } else {
          openErrorNotification("Please enter valid credentials.");
        }
      }
    } catch (error: any) {
      setIsLoading(false);
      openErrorNotification(
        isTruthy(error.errorMessage)
          ? error.errorMessage
          : notifiers.GENERIC_ERROR
      );
    }
  };

  // const handleValidation = () => {
  //   const { isValid, errors } = loginValidation(formFields);
  //   setFormFields({ ...errors });
  //   return isValid;
  // };
  const handleValidation = () => {
    const { isValid, errors } = loginValidation(formFields);
    setFormFields({ ...errors });
    return isValid;
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Stack
        direction="column"
        justifyContent="flex-start"
        sx={classes.loginBox}
      >
        <LoginInputs
          classes={classes}
          formFields={formFields}
          setFormFields={setFormFields}
          passwordField={true}
          handleSubmit={handleLogin}
        />
        <Box m={1}>
          <ReCAPTCHA
            // onChange={handleRecaptchaChange}
            sitekey={RECAPTCHA_SITE_KEY}
            size="invisible"
            ref={reRef}
          />
        </Box>
        <CustomButton
          label="Login"
          variant="contained"
          onClick={handleLogin}
          buttonType="primaryBtn"
          customClasses={classes.loginBtn}
          loading={isLoading}
        />
      </Stack>
    </Box>
  );
};
export default Login;
