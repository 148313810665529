import { StringConstants } from "./StringConstants";

class NotificationConstants extends StringConstants {
  GENERIC_ERROR = "Something went wrong! Please try again";
  TOKEN_EXPIRED_ERROR = "Your session has expired. Please login again.";
  DOCUMENT = "Please upload document.";
  EDIT_PERMISSION_Permission =
    "Your  role does not  allowed to edit  document.";
  AUTHORIZATION_PERMISSIONS = "You are not authorized  to access";
}

let notifiers = new NotificationConstants();
export default notifiers;
