import { StringConstants } from "./StringConstants";

class UrlConstants extends StringConstants {
  PROD = false;

  url_prod = "<PROD_BACKEND_URL>";
  // url_dev = "<DEV_BACKEND_URL>";
  url_dev = "https://uat-api.vedi.love/public";

  url = this.PROD ? this.url_prod : this.url_dev;

  // View Paths
  landingViewPath = "/";
  chatViewPath = "/chat";
  forgotPasswordViewPath = "/forgot-password";
  resetPasswordViewPath = "/changepwd";
  ticketsViewPath = "/tickets";
  settingsViewPath = "/settings";
  employeesViewPath = "/employees";
  employeesAddEditViewPath = "/employees/edit";

  // Base Folders
  auth = "auth";
  ERP = "erp";

  // Endpoint
  REFRESHTOKEN = `${this.url}/auth/refreshToken`;

  //emaple urls
  dashboardViewPath = "/dashboard";

  GET_LANGUAGES = `${this.url}/language`;
  GET_MODEL_NAME = `${this.url}/llm-model`;
  TRANSLATE = `${this.url}/translate`;
  GENERATE = `${this.url}/generate`;
  FETCH_DATA = `${this.url}/fetch-data`;
  QUESTION_ANSWERS = `${this.url}/question-answer`;
}

let urls = new UrlConstants();
export default urls;
