import { Box, Typography } from "@mui/material";
import PageNotFoundStyles from "./PageNotFound.styles";
import PageNotFoundImage from "../../assets/images/PageNotFoundImage.svg";
import { CustomButton } from "../../global/components";
import strings from "../../global/constants/StringConstants";
import history from "../../utils/history";
import { useAppSelector } from "../../utils/hooks";
import { selectAuthenticated } from "../../redux/authSlice";
import urls from "../../global/constants/UrlConstants";

const PageNotFound = () => {
  const classes = PageNotFoundStyles;
  const isAuthenticated = useAppSelector(selectAuthenticated);

  const handleBackToHome = () => {
    if (isAuthenticated) {
      // history.push(urls.dashboardViewPath);
      history.goBack();
      return null;
    } else {
      return urls.landingViewPath;
    }
  };

  const getBody = () => {
    return (
      <Box sx={classes.mainContainer}>
        <Box>
          <img src={PageNotFoundImage} alt="Page not found image" />
        </Box>
        <Box>
          <Typography sx={classes.fontSize}>
            Oops!! It looks like something went wrong.
          </Typography>
          <Typography>
            Page Not Found. This doesn’t exist or was removed! We suggest you
            back to home.
          </Typography>
        </Box>
        <Box mt={2}>
          <CustomButton
            label={strings.BACK_TO_HOME}
            buttonType="primaryBtn"
            customClasses={classes.homeBtn}
            onClick={handleBackToHome}
          />
        </Box>
      </Box>
    );
  };

  return <>{getBody()}</>;
};

export default PageNotFound;
