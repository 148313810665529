import { theme } from "../../../utils/styles";

const customDrawerStyles = {
  drawerStyle: {
    width: "600px",
    [theme.breakpoints.down("sm")]: {
      width: "380px",
    },
    [theme.breakpoints.down(370)]: {
      width: "100%",
    },
  },
};
export default customDrawerStyles;
