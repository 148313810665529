import Checkbox, { CheckboxProps } from "@mui/material/Checkbox";
import InputBase from "@mui/material/InputBase";
import Radio, { RadioProps } from "@mui/material/Radio";
// import { withStyles, createStyles, CSSProperties } from "@mui/material/styles";
import { Theme } from "@mui/material";
import Switch from "@mui/material/Switch";
import { createStyles, createTheme, withStyles } from "@mui/material/styles";
import { CSSProperties } from "@mui/material/styles/createMixins";

const primaryColorWhite = "#FFFFFF";
const primaryBackgroundColor = "#F0F0F0";
const borderColor = "rgba(0, 0, 0, 0.12)";
const borderStyle = "1px solid " + borderColor;
const infoTextColor = "#888888";
const defaultFontSize = 14;
const defaultBoxShadow = "0 0 0 0.2rem rgb(0 123 255 / 25%)";
const drawerWidth = 300;
const primaryColorBlue = "#24CBC7";
const primaryColorPurple = "#6842EF";
const primaryColorYellow = "#FFD600";
const primaryColorBlack = "#202730";
const primaryActiveTabBgColor = "#F0ECFF";
const primaryInactiveTabBgColor = "#F9F9F9";
const primaryColorOrange = "#D64430";
const primaryColorGreen = "#39906D";
const primaryBorderColor = "#E7E7E7";
const primaryColor = "#0DD0C4";
const chipBackgroundColor = "#FFF1F9";
const completeChipBackgroundColor = "#E7EEBD";
const lightTextColor = "#666";
const textLightColor = "#666666";
const primaryHeadingColor = "#001529";
const inputLabelRequiredColor = "red";

const borderRadius = "30px";

const blueAsteriskColor = "#24CBC7";
// for responsive
const boldFamily = "MyriadPro_Bold";
const regularFamily = "MyriadPro_Regular";
const proLightFamily = "TT_Norms_Pro_Light";
const mediumFamily = "MyriadPro_Medium";
const digitalMonoFamily = "Digital-7 Mono";
const sansSerifFamily = "Roboto, Helvetica, Arial, sans-serif !important";

const mainContainer: CSSProperties = {
  margin: "30px",
};

const lightFont = {
  fontFamily: "MyriadPro_Light",
  fontWeight: 900,
  fontStyle: "normal",
};

const boldFont = {
  fontFamily: "MyriadPro_Bold",
  fontWeight: 700,
};

const mediumFont = {
  fontFamily: "MyriadPro_Medium",
  fontWeight: 500,
};

const regularFont = {
  fontFamily: "MyriadPro_Regular",
  fontWeight: 400,
};

const digitalFont = {
  fontFamily: "Digital-7 Mono",
  fontWeight: 400,
};

const theme = createTheme({
  typography: {
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      "MyriadPro_Light",
      "MyriadPro_Bold",
      "MyriadPro_Medium",
      "MyriadPro_Regular",
      "sans-serif",
      "Digital-7 Mono",
    ].join(","),
  },
  // overrides: {
  //   MuiCssBaseline: {
  //     "@global": {
  //       "@font-face": [raleway],
  //     },
  //   },
  // },
});

const getRelativeFontSize = (value: number = 0) => {
  let size = defaultFontSize + value;
  return size + "px";
};

const customButtonStyle = {
  borderRadius: borderRadius,
  border: "none",
  fontSize: getRelativeFontSize(2),
  textAlign: "center",
  backgroundColor: primaryColorWhite,
  padding: "10px 15px",
  boxShadow: "none",
  color: "white",
  cursor: "pointer",
  textTransform: "none",
};

const customTextFieldStyle = {
  borderRadius: borderRadius,
  position: "relative",
  border: "none",
  fontSize: getRelativeFontSize(2),
  backgroundColor: primaryBackgroundColor,
  padding: "10px 15px",
  boxShadow: "none",
  width: "100%",
};

const headingText = {
  display: "inline-block",
  fontSize: getRelativeFontSize(8),
};

const centerItemFlex = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
};

const centerItemAbsolute = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%,-50%)",
};

export {
  borderRadius,
  primaryColorWhite,
  primaryBackgroundColor,
  borderColor,
  borderStyle,
  infoTextColor,
  primaryColorBlue,
  primaryColorPurple,
  primaryColorYellow,
  primaryColorBlack,
  primaryActiveTabBgColor,
  primaryInactiveTabBgColor,
  primaryColorGreen,
  primaryColorOrange,
  defaultBoxShadow,
  customButtonStyle,
  customTextFieldStyle,
  headingText,
  centerItemFlex,
  centerItemAbsolute,
  regularFont,
  boldFont,
  mediumFont,
  lightFont,
  getRelativeFontSize,
  theme,
  drawerWidth,
  mainContainer,
  digitalFont,
  primaryBorderColor,
  primaryColor,
  blueAsteriskColor,
  boldFamily,
  regularFamily,
  proLightFamily,
  mediumFamily,
  digitalMonoFamily,
  sansSerifFamily,
  chipBackgroundColor,
  completeChipBackgroundColor,
  lightTextColor,
  primaryHeadingColor,
  textLightColor,
  inputLabelRequiredColor,
};
