import { Box } from "@mui/material";
import { Route, Router, Switch } from "react-router-dom";
import withClearCache from "./ClearCache";
import urls from "./global/constants/UrlConstants";
import LandingPage from "./screens/LandingPage/LandingPage";
import PageNotFound from "./screens/PageNotFound/PageNotFound";
import Layout from "./screens/Shared/Layout/Layout";
import history from "./utils/history";
import Notifications from "./utils/notifications";

const App = () => {
  return <ClearCacheComponent />;
};

const MainApp = () => {
  return (
    <Box>
      <Router history={history}>
        <Switch>
          <Route exact path={[urls.landingViewPath]} component={LandingPage} />
          <Layout />
          <Route path={""} component={PageNotFound} />
        </Switch>
      </Router>
      <Notifications />
    </Box>
  );
};

const ClearCacheComponent = withClearCache(MainApp);

export default App;
