import {
  boldFont,
  centerItemFlex,
  getRelativeFontSize,
  mediumFont,
  primaryColorBlack,
  primaryColorPurple,
  regularFont,
  theme,
} from "../../utils/styles";

const authStyles = {
  logoBox: {
    display: "flex",
    alignItems: "center",
  },
  loginBox: {
    marginY: theme.spacing(6),
    marginX: theme.spacing(8),
    [theme.breakpoints.down("sm")]: {
      marginX: theme.spacing(4),
    },
    [theme.breakpoints.up("md")]: {
      marginX: theme.spacing(4),
    },
  },
  workText: {
    ...boldFont,
    display: "flex",
    color: primaryColorBlack,
  },
  sageText: {
    ...boldFont,
    color: "#828282",
  },
  welcomeText: {
    ...boldFont,
    color: primaryColorPurple,
    fontSize: getRelativeFontSize(17),
    [theme.breakpoints.down("sm")]: {
      fontSize: getRelativeFontSize(8),
    },
    [theme.breakpoints.down("xl")]: {
      fontSize: getRelativeFontSize(10),
    },
  },
  workSageText: {
    ...boldFont,
    color: primaryColorBlack,
    fontSize: getRelativeFontSize(17),
    [theme.breakpoints.down("sm")]: {
      fontSize: getRelativeFontSize(8),
    },
    [theme.breakpoints.down("xl")]: {
      fontSize: getRelativeFontSize(10),
    },
  },
  loginBoxText: {
    ...mediumFont,
    marginTop: theme.spacing(1),
    [theme.breakpoints.down("sm")]: {
      fontSize: getRelativeFontSize(3),
    },
  },
  signInText: {
    ...boldFont,
    color: primaryColorBlack,
    marginTop: theme.spacing(10),
    [theme.breakpoints.down("sm")]: {
      marginTop: theme.spacing(7),
    },
    [theme.breakpoints.down("xl")]: {
      marginTop: theme.spacing(5),
    },
  },
  center: {
    ...centerItemFlex,
  },
  gridItem: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  forgotText: {
    ...boldFont,
    cursor: "pointer",
    color: primaryColorBlack,
    fontSize: getRelativeFontSize(),
  },
  loginBtn: {
    minWidth: "100%",
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(4),
  },
  loader: {
    ...centerItemFlex,
    color: primaryColorPurple,
  },
  errorStyling: {
    paddingLeft: "14px",
  },
  mainBox: {
    marginTop: theme.spacing(6),
    marginLeft: theme.spacing(8),
    marginRight: theme.spacing(8),
    [theme.breakpoints.down("sm")]: {
      marginLeft: theme.spacing(4),
      marginRight: theme.spacing(4),
    },
  },
  centerItem: {
    ...centerItemFlex,
    flexDirection: "column",
  },
  resetText: {
    ...boldFont,
  },
  customButtonStyle: {
    marginTop: theme.spacing(2),
    borderRadius: "10px",
    minWidth: "100%",
  },
  backToLoginStyle: {
    ...regularFont,
    cursor: "pointer",
  },
  workText1: {
    ...boldFont,
    display: "flex",
    color: primaryColorBlack,
    [theme.breakpoints.up("sm")]: {
      fontSize: "2.125rem",
    },
    [theme.breakpoints.up("xs")]: {
      fontSize: "1.875rem",
    },
    [`@media screen and (max-width: ${1370}px)`]: {
      fontSize: "2.125rem",
    },
  },
  // sageText: {
  //   ...boldFont,
  //   color: "#828282",
  //   [theme.breakpoints.up("sm")]: {
  //     fontSize: "2.125rem",
  //   },
  //   [theme.breakpoints.up("xs")]: {
  //     fontSize: "1.5rem",
  //   },
  //   [`@media screen and (max-width: ${1370}px)`]: {
  //     fontSize: "2.125rem",
  //   },
  // },

  // welcomeText: {
  //   ...boldFont,
  //   color: primaryColorPurple,
  //   fontSize: getRelativeFontSize(17),
  //   [theme.breakpoints.down("sm")]: {
  //     fontSize: getRelativeFontSize(8),
  //   },
  //   [theme.breakpoints.down("xl")]: {
  //     fontSize: getRelativeFontSize(10),
  //   },
  //   [theme.breakpoints.up("sm")]: {
  //     fontSize: "1.938rem",
  //   },
  //   [theme.breakpoints.up("xs")]: {
  //     fontSize: "1.875rem",
  //   },
  //   [`@media screen and (max-width:${1370}px)`]: {
  //     fontSize: "1.5rem",
  //   },
  // },

  // workSageText: {
  //   ...boldFont,
  //   color: primaryColorBlack,
  //   fontSize: getRelativeFontSize(17),
  //   [theme.breakpoints.down("sm")]: {
  //     fontSize: getRelativeFontSize(8),
  //   },
  //   [theme.breakpoints.down("xl")]: {
  //     fontSize: getRelativeFontSize(10),
  //   },
  //   [theme.breakpoints.up("sm")]: {
  //     fontSize: "1.938rem",
  //   },
  //   [theme.breakpoints.up("xs")]: {
  //     fontSize: "1.875rem",
  //   },
  //   [`@media screen and (max-width:${1370}px)`]: {
  //     fontSize: "1.5rem",
  //   },
  // },

  // loginBoxText: {
  //   ...mediumFont,
  //   marginTop: theme.spacing(2),
  //   [theme.breakpoints.down("sm")]: {
  //     fontSize: getRelativeFontSize(3),
  //   },
  // },

  // signInText: {
  //   ...boldFont,
  //   color: primaryColorBlack,
  //   marginTop: theme.spacing(10),
  //   [theme.breakpoints.down("sm")]: {
  //     marginTop: theme.spacing(7),
  //   },
  //   [theme.breakpoints.down("xl")]: {
  //     marginTop: theme.spacing(5),
  //   },
  //   [theme.breakpoints.up("sm")]: {
  //     fontSize: "2.125rem",
  //   },
  //   [theme.breakpoints.up("xs")]: {
  //     fontSize: "2rem",
  //   },
  //   [theme.breakpoints.up("lg")]: {
  //     fontSize: "2.125rem",
  //   },
  //   [`@media screen and (max-width:${1370}px)`]: {
  //     marginTop: theme.spacing(2),
  //   },
  // },

  customInput: {
    marginTop: theme.spacing(8),
    [theme.breakpoints.down("xl")]: {
      marginTop: theme.spacing(3),
    },
  },

  // loginBtn: {
  //   borderRadius: "10px",
  //   marginTop: theme.spacing(4),
  //   minWidth: "100%",
  // },

  checkBox: {
    color: primaryColorPurple,
    marginLeft: theme.spacing(-1),
    "&.Mui-checked": {
      color: primaryColorPurple,
    },
  },
  rememberMeText: {
    ...regularFont,
    color: primaryColorBlack,
    fontSize: getRelativeFontSize(),
  },

  // forgotText: {
  //   ...mediumFont,
  //   cursor: "pointer",
  //   color: primaryColorBlack,
  //   fontSize: getRelativeFontSize(),
  // },

  footer: {
    ...centerItemFlex,
    width: "27vw",
    position: "fixed",
    bottom: 10,
    color: primaryColorBlack,
    marginBottom: 1,
  },
  footerText: {
    ...mediumFont,
    fontSize: getRelativeFontSize(-1),
    [theme.breakpoints.down("xl")]: {
      fontSize: getRelativeFontSize(-2),
    },
  },

  // loader: {
  //   ...centerItemFlex,
  //   color: primaryColorPurple,
  // },

  // errorStyling: {
  //   paddingLeft: "14px",
  // },
} as const;

export default authStyles;
