import Box from "@mui/material/Box";
import FormHelperText from "@mui/material/FormHelperText";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import customSelectStyles from "./CustomSelect.styles";

interface CustomProps {
  menuItems: any;
  onChange: any;
  id: string;
  name: string;
  value: any;
  label?: string;
  required?: boolean;
  placeHolder?: string;
  customClasses?: any;
  inputProps?: any;
  disabled?: boolean;
  error?: any;
  ref?: any;
}

const CustomSelect = (props: CustomProps) => {
  const classes = customSelectStyles;
  const InputProps = {
    classes: {
      icon: classes.icon,
    },
  };
  const sx = props.customClasses
    ? { ...classes.selectStyle, ...props.customClasses }
    : classes.selectStyle;

  return (
    <>
      {props.label && (
        <Box mb={props.label ? 1 : 0}>
          <InputLabel required={props.required} sx={classes.nameField}>
            {props.label}
          </InputLabel>
        </Box>
      )}
      <Select
        sx={props.error ? { ...sx, ...classes.errorTextField } : sx}
        ref={props.ref}
        id={props.id}
        name={props.name}
        value={props.value}
        placeholder={props.placeHolder}
        onChange={props.onChange}
        inputProps={
          props.inputProps ? [props.inputProps, InputProps] : InputProps
        }
        disabled={props.disabled}
      >
        {props.menuItems.map((menu: any, index: number) => (
          <MenuItem
            key={menu.index}
            value={menu.value}
            disabled={menu.disabled}
            selected={menu.selected}
          >
            {menu.content}
          </MenuItem>
        ))}
      </Select>
      {props.error && (
        <FormHelperText error sx={classes.errorStyle}>
          {props.error}
        </FormHelperText>
      )}
    </>
  );
};

export default CustomSelect;
