import {
  boldFont,
  centerItemFlex,
  primaryColorBlack,
  regularFont,
  theme,
} from "../../utils/styles";

const landingPageStyles = {
  mainBox: {
    height: "100vh",
  },
  loginImgBox: {
    ...centerItemFlex,
    background: "#212121",
    height: "100vh",
    // width: "100%",
  },
  loginFieldBox: {
    ...centerItemFlex,
    background: "#171717",
    height: "100vh",
    // width: "100%",
  },
  loginImgText: {
    ...boldFont,
    marginTop: theme.spacing(5),
  },
  subtitleText: {
    ...regularFont,
    marginTop: theme.spacing(2),
    textAlign: "center",
  },
  eyeIcon: {
    color: primaryColorBlack,
    marginRight: theme.spacing(1),
  },
} as const;

export default landingPageStyles;
