import strings from "../global/constants/StringConstants";
import urls from "../global/constants/UrlConstants";
import { FormattedResources, Resources } from "../models/interfaces";
import { logOutAction } from "../redux/authSlice";
import { globalEmitter } from "../utils/emitter";
import history from "../utils/history";
import { store } from "../utils/store";

const methodsContext = this;

export const isTruthy = (value: any, shouldCheckByType: boolean = true) => {
  const validatedByType = shouldCheckByType
    ? customValidatorByType(value)
    : true;

  if (value !== null && value !== undefined && validatedByType) {
    return true;
  }
  return false;
};

const customValidatorByType = (value: any) => {
  if (value !== undefined && value !== null) {
    const type = typeof value;
    switch (type) {
      case "string":
        return value.trim() !== "";
      case "object":
        if (Array.isArray(value)) {
          return value.length > 0;
        } else {
          return Object.keys(value).length > 0;
        }
      default:
        return true;
    }
  }
};

export const compareStrings = (string1: string, string2: string) => {
  if (!(isTruthy(string1) || isTruthy(string2))) {
    return true;
  } else {
    if (string1 && string2) {
      if (string1.toLowerCase() === string2.toLowerCase()) {
        return true;
      }
    }
  }
  return false;
};

export const openInfoNotification = (message: any, title: string = "Info") => {
  globalEmitter.emit(strings.notification, {
    type: strings.info,
    message: message,
    title: title,
  });
};

export const openSuccessNotification = (
  message: any,
  title: string = "Success"
) => {
  globalEmitter.emit(strings.notification, {
    type: strings.success,
    message: message,
    title: title,
  });
};

export const openWarningNotification = (
  message: any,
  title: string = "Warning"
) => {
  globalEmitter.emit(strings.notification, {
    type: strings.warning,
    message: message,
    title: title,
  });
};

export const openErrorNotification = (
  message: any,
  title: string = "Error"
) => {
  globalEmitter.emit(strings.notification, {
    type: strings.error,
    message: message,
    title: title,
  });
};

export function debounce(func: Function, wait: number) {
  let timeout: any;
  return function (...args: any) {
    const context = methodsContext;
    clearTimeout(timeout);
    timeout = setTimeout(() => func.apply(context, args), wait);
  };
}

export function debounceEventHandler(func: Function, wait: number) {
  const debounced = debounce(func, wait);
  return function (event: any) {
    event.persist();
    return debounced(event);
  };
}

export const getFormattedNumbers = (value: string) => {
  const matches = value.match(/\d+/g);
  let number = "";
  if (matches !== null) {
    matches.forEach((match) => {
      number = number + match;
    });
  }
  if (number.length === 10) {
    value = number.replace(/^(\d{3})(\d{3})(\d{4})/, "($1) $2-$3");
  }
  return { number: number, maskedNumber: value };
};

export const getFileExtension = (fileName: string) => {
  const ext = /^.+\.([^.]+)$/.exec(fileName);
  return ext === null ? "" : ext[1];
};

export const doesResourcesExistInLocalStorage = (resources: Resources[]) => {
  if (!isTruthy(resources)) {
    store.dispatch(logOutAction());
    history.push(urls.landingViewPath);
    return false;
  }
  return true;
};

export const convertResourceToObjectFormat = (resources: Resources[]) => {
  if (!doesResourcesExistInLocalStorage(resources)) {
    return {} as FormattedResources;
  }
  let returnObject = {} as { [key: string]: string[] };
  resources.forEach((resource) => {
    returnObject[resource.name] = resource.permissions;
  });
  return returnObject;
};

export const handleErrorMessage = (error: any) => {
  if (error.errorCode) {
    switch (error.errorCode) {
      // case 400:
      //   return openErrorNotification("Please check your request and try again");
      case 401:
        store.dispatch(logOutAction());
        history.push(urls.landingViewPath);
        return null;
      // case 403:
      //   return openErrorNotification(
      //     "You are not authorized to access this resource."
      //   );
      // case 404:
      //   return openErrorNotification(
      //     "The requested resource could not be found"
      //   );
      // case 500:
      //   return openErrorNotification(
      //     "There was an error on the server side.Please try again later."
      //   );
      // case 406:
      //   return openErrorNotification(error.message);
      default:
        return openErrorNotification(error.errorMessage);
    }
  } else if (
    error.message === "Failed to fetch" &&
    error.name === "TypeError"
  ) {
    return openErrorNotification(
      "This is a critical error that requires immediate attention from the support team. Please contact them."
    );
  }
};
