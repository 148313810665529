export class StringConstants {
  PROD = false;

  APP_NAME = "Workforce";

  RECAPTCHA_SITE_KEY = "6LcfPTQiAAAAAEiV_UD6vAZCy2RkJ1heocnrPFSq";

  //App acount name
  account = "ESign";

  //contentTypes
  applicationJSON = { "Content-Type": "application/json" };
  multipartForm = {};

  notification = "notification";
  error = "error";
  success = "success";
  warning = "warning";
  info = "info";
  autoHideDuration = 3 * 1000; //in milliseconds
  workforce = "workforce";

  //errors
  AUTHORIZATION_ERROR = "Unauthorized";

  //date
  am = "AM";
  pm = "PM";

  //Email validation Regex
  regex =
    /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
  regexPassword =
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#$@!%&*?])[A-Za-z\d#$@!%&*?]{8,100}$/;
  //Phone Number Regex
  phoneNumber = /^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/;

  //file types
  jpeg = ".jpeg";
  JPEG = ".JPEG";
  JPG = "JPG";
  jpg = ".jpg";
  png = ".png";
  PNG = ".PNG";
  gif = ".gif";
  bmp = ".bmp";
  tiff = ".tiff";
  pdf = ".pdf";
  PDF = ".PDF";
  doc = ".doc";
  docx = ".docx";
  txt = ".txt";
  xls = ".xls";
  xlsx = ".xlsx";
  FileUrlPrefix = `https://view.officeapps.live.com/op/embed.aspx?src=`;

  //btn-labels
  reject = "Reject";
  submit = "Submit";
  cancel = "Cancel";
  edit = "Edit";
  save = "Save";
  close = "Close";
  add = "Add";
  createRole = "Create Role";
  editRole = "Edit Role";
  rolesTable = "Roles Table";
  done = "Done";
  back = "Back";
  next = "Next";
  send = "Send";
  BACK_TO_HOME = "Go Back";

  //common-tabs
  active = "Active";
  inactive = "Inactive";
  general = "General";
  documents = "Documents";
  users = "Users";
  roleManagement = "Role Management";

  //token not found error message
  TOKEN_INVALID_ERROR = "Forbidden";
  EVENT_TYPE = "storage";

  //roles
  PROCESS_ADMIN = "Process Admin";

  // Permissions
  adminPermission = "admin";
  selfPermission = "self";
  fetchPermission = "fetch";
  addPermission = "add";
  updatePermission = "update";
  uploadPermission = "upload";
  deletePermission = "delete";
  inactivatePermission = "inactivate";
  activatePermission = "activate";

  //landingPages
  LOGIN = "login";
  REGISTER = "register";
  FORGOT_PASSWORD = "forgot-password";
  RESET_PASSWORD = "changepwd";

  //pages
  HOME = "Home";
  DASHBOARD = "Dashboard";
  TICKETS = "Tickets";
  SETTINGS = "Settings";
  PAGENOTFOUND = "pageNotFound";
  EMPLOYEES = "Employees";

  //resources
  APP = "app";
  ACCOUNT = "account";
  candidate = "candidate";

  //  for ticket
  assignData = "assignData";
  RaisedData = "RaisedData";
  openData = "openData";
  completedData = "completedData";
  Reassign = "Reassign";
  DrawerTitle = "Ticket Details";
  ReassignTitleValue = "New Assignee";
  //ticketTabs
  assignedToMe = "Assigned To Me";
  raisedByMe = "Raised By Me";
  completedTickets = "Completed Tickets";
  openTickets = "Open Tickets";
  PROCESSADMIN = "Process Admin";
  USER_DESCRIPTION_LIMIT = 500;
  TICKET_USER_TITLE_LIMIT = 50;

  SEARCH_TIME_OUT = 500;
}

let strings = new StringConstants();
export default strings;
