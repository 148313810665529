import { Box, Grid, Stack, useMediaQuery } from "@mui/material";
// import loginImg from "../../assets/images/LoginImg.svg";
import loginImg from "../../assets/images/logo.png";
import strings from "../../global/constants/StringConstants";
import { selectAuthenticated } from "../../redux/authSlice";
import {
  MainMenus,
  doesUserHasAccessToMenuItem,
} from "../../utils/AuthorizationManager";
import history from "../../utils/history";
import { useAppSelector } from "../../utils/hooks";
import { theme } from "../../utils/styles";
import Login from "../Auth/Login";
import landingPageStyles from "./LandingPage.styles";

interface CustomProps {
  location?: Location;
}

const LandingPage = (props: CustomProps) => {
  const classes = landingPageStyles;
  const isAuthenticated = useAppSelector(selectAuthenticated);
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));

  const staticImageComponent = () => {
    return (
      <Stack direction="column" alignItems="center">
        <Box>
          <img src={loginImg} alt="" height={"150px"} />
        </Box>
      </Stack>
    );
  };

  const getComponentBasedOnURL = () => {
    const location = props.location?.pathname?.split("/")[1].toLowerCase();
    switch (location) {
      case strings.LOGIN: {
        return <Login />;
      }
      default: {
        return <Login />;
      }
    }
  };

  const getLandingPage = () => {
    return (
      <Grid container sx={classes.mainBox}>
        {isDesktop && (
          <Grid
            item
            xs={12}
            sm={12}
            md={8}
            lg={8}
            xl={8}
            sx={classes.loginImgBox}
          >
            {staticImageComponent()}
          </Grid>
        )}
        <Grid
          item
          xs={12}
          sm={12}
          md={4}
          lg={4}
          xl={4}
          sx={classes.loginFieldBox}
        >
          {getComponentBasedOnURL()}
        </Grid>
      </Grid>
    );
  };
  const redirectTo = () => {
    for (let menu of MainMenus) {
      if (doesUserHasAccessToMenuItem(menu.pageName)) {
        history.push(menu.key);
        break;
      }
    }
  };

  if (isAuthenticated) {
    redirectTo();
    return null;
  } else {
    return getLandingPage();
  }
};

export default LandingPage;
