import {
  getRelativeFontSize,
  primaryBorderColor,
  theme,
} from "../../../utils/styles";

const bootstrapInputStyles = {
  root: {
    width: "100%",
    display: "flex",
    justifyContent: "end",
  },
  input: {
    position: "relative",
    fontSize: getRelativeFontSize(),
    width: "100%",
    padding: "5px 10px",
    "label + &": {
      marginTop: theme.spacing(3),
    },
    backgroundColor: theme.palette.common.white,
    border: "1px solid",
    borderColor: primaryBorderColor,
    borderRadius: "10px",
    transition: theme.transitions.create([
      "border-color",
      "background-color",
      "box-shadow",
    ]),
  },
};

export default bootstrapInputStyles;
