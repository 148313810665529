import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../utils/store";
import { FormattedResources } from "../models/interfaces";

export interface AuthState {
  authenticated: boolean;
  accessToken: string;
  userName: string;
  userRole: string;
  userEmail: string;
  userId: string;
  resources: FormattedResources;
}

const initialState: AuthState = {
  authenticated: false,
  accessToken: "",
  userName: "",
  userRole: "",
  userEmail: "",
  userId: "",
  resources: {},
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    loginAction: (
      state,
      action: PayloadAction<{
        authenticated: boolean;
        accessToken: string;
        userName: string;
        userRole: string;
        userEmail: string;
        resources: FormattedResources;
      }>
    ) => {
      state.authenticated = action.payload.authenticated;
      state.accessToken = action.payload.accessToken;
      state.userName = action.payload.userName;
      state.userRole = action.payload.userRole;
      state.userEmail = action.payload.userEmail;
      state.resources = action.payload.resources;
    },
    logOutAction: (state) => {
      state.authenticated = false;
      state.accessToken = "";
      state.userName = "";
      state.resources = {};
    },
  },
});

export const { loginAction, logOutAction } = authSlice.actions;

export const selectAuthenticated = (state: RootState) =>
  state.auth.authenticated;
export const selectAccessToken = (state: RootState) => state.auth.accessToken;
export const selectResources = (state: RootState) => state.auth.resources;
export const selectEmail = (state: RootState) => state.auth.userEmail;
export const selectRole = (state: RootState) => state.auth.userRole;

export default authSlice.reducer;
