import {
  centerItemFlex,
  customButtonStyle,
  primaryBorderColor,
  primaryColorWhite,
  regularFont,
} from "../../../utils/styles";

const customButtonStyles = {
  btnStyle: {
    ...regularFont,
    ...customButtonStyle,
    ...centerItemFlex,
    minWidth: "200px",
    color: "#000000",
    border: "1px solid",
    borderColor: primaryBorderColor,
    borderRadius: "12px",
    "&": {
      height: "42px",
      boxShadow: "none",
    },
  },
  loading: {
    width: "100%",
    ...centerItemFlex,
  },
  primaryBtn: {
    color: "#000000",
    background: "#ffffff",
    borderColor: "#ffffff",
    "&:hover": {
      background: "#212121",
      color: "#ffffff",
    },
  },
  secondaryBtn: {
    background: primaryColorWhite,
    color: "#828282",
    "&:hover": {
      background: primaryColorWhite,
    },
  },
} as const;

export default customButtonStyles;
